import { Capacitor } from '@capacitor/core';
import { matchRoutes } from 'react-router-dom';

import {
  exclusionPathMapRoutes,
  pathMap,
  pathMapRoutes,
} from './desktop-redirects';
import { config } from 'config';

/**
 * Get the configured Desktop site redirect uri for a given location
 */
export const getDesktopSiteRedirect = (
  location: { pathname: string; search: string },
  addRoutes: Record<'path', string>[]
) => {
  const { pathname, search } = location;

  let generatedExclusionPaths: Record<'path', string>[] =
    exclusionPathMapRoutes;

  // Allows additionals routes from LaunchDarkly, etc calculated at render time
  if (addRoutes?.length) {
    generatedExclusionPaths = [...exclusionPathMapRoutes, ...addRoutes];
  }

  const [excludeMatch] = matchRoutes(generatedExclusionPaths, pathname) || [];

  if (!excludeMatch) {
    const [match] = matchRoutes(pathMapRoutes, pathname) || [];
    const routePath = match ? match.route.path : '';
    const pathConfig = pathMap[routePath];
    let newRedirectPath = pathConfig?.redirect;

    if (newRedirectPath) {
      // Resolve redirect path params
      Object.keys(match.params).forEach((param) => {
        const paramValue = match.params[param];
        if (paramValue && newRedirectPath) {
          newRedirectPath = newRedirectPath.replaceAll(
            `:${param}`,
            encodeURIComponent(paramValue)
          );
        }
      });
    }

    // if we have a new redirect path, use it, otherwise use the current path
    // paths sometimes diverge between the two sites
    const resolvedRedirect = `${config.legacyRedirect.url}${
      newRedirectPath || pathname
    }${search}`;
    return resolvedRedirect;
  }

  return undefined;
};

/**
 * Determine whether the current device should be redirected to the Desktop site
 */
export const getIsDesktopRedirectDevice = (userAgent: string) =>
  // Redirect on presence of iphone/ipad/android in useragent
  !['iphone', 'ipad', 'android'].some((device) =>
    userAgent.toLowerCase().includes(device)
  ) && !Capacitor.isNativePlatform();
